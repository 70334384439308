var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-v2-post m-sideblock"},[_c('div',{staticClass:"m-guide-header m-sideblock-header"},[_c('div',{staticClass:"u-left"},[_c('i',{staticClass:"u-icon el-icon-s-management"}),_c('span',{staticClass:"u-title"},[_vm._v("最新作品")]),_c('mini-bread',{staticClass:"u-bread",attrs:{"name":"index_topics"}})],1),_c('div',{staticClass:"u-right"},[_c('a',{staticClass:"u-more",attrs:{"href":_vm.more_link,"target":"_blank","rel":"noopener noreferrer","title":"查看全部"}},[_c('i',{staticClass:"el-icon-more"})])])]),_c('div',{staticClass:"m-v2-post-box"},[_c('div',{staticClass:"m-v2-post-header"},[_c('el-tabs',{model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('el-tab-pane',{attrs:{"name":"all"}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("全部")])]),_vm._l((_vm.links),function(item,i){return _c('el-tab-pane',{key:i,attrs:{"label":item.label,"name":item.slug}})})],2)],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"m-v2-post-wrapper"},[(_vm.isPost)?_c('div',{staticClass:"m-v2-post-content"},_vm._l((_vm.data),function(item,i){return _c('a',{directives:[{name:"reporter",rawName:"v-reporter",value:({
                        data: {
                            href: _vm.reportLink(_vm.getLink(item.post_type, item.ID)),
                            category: item.post_type,
                            aggregate: _vm.aggregate,
                        },
                        caller: 'index_lastest_artwork_click',
                    }),expression:"{\n                        data: {\n                            href: reportLink(getLink(item.post_type, item.ID)),\n                            category: item.post_type,\n                            aggregate: aggregate,\n                        },\n                        caller: 'index_lastest_artwork_click',\n                    }"}],key:i,staticClass:"u-post",attrs:{"href":_vm.getLink(item.post_type, item.ID),"target":_vm.target}},[_c('el-image',{staticClass:"u-avatar",attrs:{"src":_vm.showPostAvatar(item),"fit":"cover","alt":item.author_info && item.author_info.display_name}}),_c('div',{staticClass:"u-info"},[_c('i',{staticClass:"el-icon-collection-tag"}),_c('span',{staticClass:"u-type",attrs:{"target":"_blank"}},[_vm._v(_vm._s(_vm.formatTypeName(item.post_type)))]),_vm._v(" ／ "),_c('span',{staticClass:"u-author",attrs:{"href":_vm.authorLink(item.post_author),"target":"_blank"}},[_vm._v(_vm._s((item.author_info && item.author_info.display_name) || "匿名"))]),_c('span',{staticClass:"u-date"},[_c('i',{staticClass:"el-icon-refresh"}),_vm._v(" "+_vm._s(_vm.dateFormat(item.post_modified))+" ")])]),_c('span',{staticClass:"u-title"},[_c('i',{staticClass:"el-icon-reading"}),_vm._v(" "+_vm._s(item.post_title || "无标题")+" ")])],1)}),0):(_vm.isWiki)?_c('div',{staticClass:"m-v2-post-content"},_vm._l((_vm.data),function(item,i){return _c('a',{directives:[{name:"reporter",rawName:"v-reporter",value:({
                        data: {
                            href: _vm.reportLink(_vm.getLink(item.type, item.source_id)),
                            category: item.type,
                            aggregate: _vm.aggregate,
                        },
                        caller: 'index_lastest_artwork_click',
                    }),expression:"{\n                        data: {\n                            href: reportLink(getLink(item.type, item.source_id)),\n                            category: item.type,\n                            aggregate: aggregate,\n                        },\n                        caller: 'index_lastest_artwork_click',\n                    }"}],key:i,staticClass:"u-post",attrs:{"href":_vm.getLink(item.type, item.source_id),"target":_vm.target}},[_c('el-image',{staticClass:"u-avatar",attrs:{"src":_vm.showWikiAvatar(item),"fit":"cover"}}),_c('div',{staticClass:"u-info"},[_c('i',{staticClass:"el-icon-collection-tag"}),_c('span',{staticClass:"u-type",attrs:{"target":"_blank"}},[_vm._v(_vm._s(_vm.formatTypeName(item.type)))]),_vm._v(" ／ "),_c('span',{staticClass:"u-author",attrs:{"href":_vm.authorLink(item.user_id),"target":"_blank"}},[_vm._v(_vm._s(item.user_nickname || "匿名"))]),_c('span',{staticClass:"u-date"},[_c('i',{staticClass:"el-icon-refresh"}),_vm._v(" "+_vm._s(_vm.wikiDate(item.updated))+" ")])]),_c('span',{staticClass:"u-title"},[_c('i',{staticClass:"el-icon-reading"}),_vm._v(" "+_vm._s(item.title || "无标题")+" ")])],1)}),0):_c('div',{staticClass:"m-v2-post-content"},_vm._l((_vm.data),function(item,i){return _c('a',{directives:[{name:"reporter",rawName:"v-reporter",value:({
                        data: {
                            href: _vm.reportLink(item.link || _vm.getLink(item.type, item.source_id)),
                            category: item.type,
                            aggregate: _vm.aggregate,
                        },
                        caller: 'index_lastest_artwork_click',
                    }),expression:"{\n                        data: {\n                            href: reportLink(item.link || getLink(item.type, item.source_id)),\n                            category: item.type,\n                            aggregate: aggregate,\n                        },\n                        caller: 'index_lastest_artwork_click',\n                    }"}],key:i,staticClass:"u-post",attrs:{"href":item.link || _vm.getLink(item.type, item.source_id),"target":_vm.target}},[_c('el-image',{staticClass:"u-avatar",attrs:{"src":_vm.showWikiAvatar(item),"fit":"cover"}}),_c('div',{staticClass:"u-info"},[_c('i',{staticClass:"el-icon-collection-tag"}),_c('span',{staticClass:"u-type",attrs:{"target":"_blank"}},[_vm._v(_vm._s(_vm.formatOtherTypeName(item.type)))]),_vm._v(" ／ "),_c('span',{staticClass:"u-author",attrs:{"href":_vm.authorLink(item.user_id),"target":"_blank"}},[_vm._v(_vm._s(item.user_nickname || "匿名"))]),_c('span',{staticClass:"u-date"},[_c('i',{staticClass:"el-icon-refresh"}),_vm._v(" "+_vm._s(_vm.dateFormat(item.updated))+" ")])]),_c('span',{staticClass:"u-title"},[_c('i',{staticClass:"el-icon-reading"}),_vm._v(" "+_vm._s(item.title || "无标题")+" ")])],1)}),0)]),_c('div',{staticClass:"m-v2-post-more"},[_c('a',{directives:[{name:"reporter",rawName:"v-reporter",value:({
                    data: {
                        href: _vm.report_link,
                    },
                    caller: 'index_lastest_artwork_more',
                }),expression:"{\n                    data: {\n                        href: report_link,\n                    },\n                    caller: 'index_lastest_artwork_more',\n                }"}],staticClass:"u-more",attrs:{"href":_vm.more_link}},[_vm._v("查看更多»")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }