var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-v2-post m-sideblock m-v3-community"},[_c('div',{staticClass:"m-guide-header m-sideblock-header"},[_c('div',{staticClass:"u-left"},[_c('i',{staticClass:"u-icon el-icon-s-comment"}),_c('span',{staticClass:"u-title"},[_vm._v("最新讨论")]),_c('mini-bread',{staticClass:"u-bread",attrs:{"name":"index_topics"}})],1),_c('div',{staticClass:"u-right"},[_c('a',{staticClass:"u-more",attrs:{"href":_vm.more_link,"target":"_blank","rel":"noopener noreferrer","title":"查看全部"}},[_c('i',{staticClass:"el-icon-more"})])])]),_c('div',{staticClass:"m-v2-post-box"},[_c('div',{staticClass:"m-v2-post-header"},[_c('el-tabs',{model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}},[_c('el-tab-pane',{attrs:{"name":"all"}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("全部")])]),_vm._l((_vm.categoryList),function(item,i){return _c('el-tab-pane',{key:i,attrs:{"label":item.name,"name":item.name}})})],2)],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"m-v2-post-wrapper"},[_c('div',{staticClass:"m-v2-post-content"},_vm._l((_vm.data),function(item,i){return _c('a',{directives:[{name:"reporter",rawName:"v-reporter",value:({
                        data: {
                            href: _vm.reportLink(_vm.getLink(_vm.appKey, item.id)),
                            category: _vm.appKey,
                            aggregate: _vm.aggregate,
                        },
                        caller: 'index_lastest_artwork_click',
                    }),expression:"{\n                        data: {\n                            href: reportLink(getLink(appKey, item.id)),\n                            category: appKey,\n                            aggregate: aggregate,\n                        },\n                        caller: 'index_lastest_artwork_click',\n                    }"}],key:i,staticClass:"u-post",attrs:{"href":_vm.getLink(_vm.appKey, item.id),"target":_vm.target}},[_c('el-image',{staticClass:"u-avatar",attrs:{"src":_vm.showPostAvatar(item.avatar),"fit":"cover","alt":item.user_name}}),_c('div',{staticClass:"u-info"},[_c('i',{staticClass:"el-icon-collection-tag"}),_c('span',{staticClass:"u-type",attrs:{"target":"_blank"}},[_vm._v(_vm._s(item.category))]),_vm._v(" ／ "),_c('span',{staticClass:"u-author",attrs:{"href":_vm.authorLink(item.user_id),"target":"_blank"}},[_vm._v(_vm._s(item.user_name || "匿名"))]),_c('span',{staticClass:"u-date"},[_c('i',{staticClass:"el-icon-refresh"}),_vm._v(" "+_vm._s(_vm.dateFormat(item.created_at))+" ")])]),_c('span',{staticClass:"u-title"},[_c('i',{staticClass:"el-icon-reading"}),_c('p',{staticClass:"u-content",domProps:{"innerHTML":_vm._s(_vm.br2nl(item.content))}})])],1)}),0)]),_c('div',{staticClass:"m-v2-post-more"},[_c('a',{directives:[{name:"reporter",rawName:"v-reporter",value:({
                    data: {
                        href: _vm.report_link,
                    },
                    caller: 'index_lastest_artwork_more',
                }),expression:"{\n                    data: {\n                        href: report_link,\n                    },\n                    caller: 'index_lastest_artwork_more',\n                }"}],staticClass:"u-more",attrs:{"href":_vm.more_link}},[_vm._v("查看更多»")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }